import classNames from 'classnames';
import styles from './index.module.scss';

const Loader = ({ btn }: { btn?: boolean }) => (
  <div className={classNames('lds-ripple', { [styles.btn]: btn })}>
    <div />
    <div />
  </div>
);

export default Loader;
