import { API_URLS } from 'constants/index';
import httpService from './http.service';

export const signIn = ({ password, email }: { password: string, email: string }) => (
  httpService.post(API_URLS.AUTH.SIGN_IN, { password, email })
);

export const signUp = (body: Record<string, any>) => (
  httpService.post(API_URLS.AUTH.SIGN_UP, body)
);

export const googleSingIn = (googleToken: string) => httpService.post(API_URLS.AUTH.GOOGLE, { googleToken });

export const getMe = () => httpService.get(API_URLS.AUTH.ME);

export const verify = (token: string) => httpService.get(API_URLS.AUTH.VERIFY, { params: { token } });

export const recovery = (email: string) => httpService.post(API_URLS.AUTH.RECOVERY, { email });

export const recoveryVerify = (payload: any) => httpService.post(API_URLS.AUTH.RECOVERY_VERIFY, payload);

export const logout = () => httpService.post(API_URLS.AUTH.LOGOUT);

export const acceptTerms = () => httpService.post(API_URLS.AUTH.ACCEPT_TERMS);
