import {
  useEffect, useRef, RefObject, useCallback,
} from 'react';
import { OutsideHookTypes } from '../../constants';

interface Outside {
  ref: RefObject<HTMLInputElement>,
  wiretapState: boolean,
  type: string,
  func: () => void,
}
const useOutside = ({
  ref, wiretapState, type, func,
}: Outside) => {
  const wiretapStateRef = useRef<boolean>();

  const handleClickOutside = useCallback((event: any): void => {
    if (
      ref.current
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
      && !ref.current.contains(event.target)
      && wiretapStateRef.current
      // && type === OutsideHookTypes.SEARCH
    ) func();
  }, [ref, type, func]);

  useEffect(() => {
    wiretapStateRef.current = wiretapState;
  }, [wiretapState]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [ref, func, handleClickOutside]);
};

export default useOutside;
