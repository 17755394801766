import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { SetStateAction, useContext } from 'react';

import { getMe } from 'services/auth.service';
import { AppContext } from 'contexts';
import { CartContext } from 'contexts/cartContext';
import { getStorage } from 'services/localStorage.service';
import { CART } from 'constants/cart';
import CartItem = Product.CartItem;

export const QueryKey = 'getMe';

const useMe = () => {
  const { upsertUser } = useContext(AppContext);
  const { setCart } = useContext(CartContext);
  return useQuery(QueryKey, () => getMe()
    .then((result: AxiosResponse<User>) => {
      if (result.data.cart.length) setCart(result.data.cart);
      upsertUser(result.data);
      return result.data;
    }).catch(() => {
      if (getStorage(CART)) setCart(getStorage(CART) as SetStateAction<CartItem[]>);
    }), {
    retry: 0,
  });
};

export default useMe;
