import { useMutation } from 'react-query';
import {
  signIn, signUp, googleSingIn, verify, logout,
} from 'services/auth.service';
import { AxiosResponse } from 'axios';

export interface GoogleResponse {
  user: User;
  token: string;
}

interface AuthMethods {
  register: (body: { email: string, password: string, username: string, terms: boolean }) => Promise<AxiosResponse<GoogleResponse, Error>>,
  login: (body: { email: string, password: string }) => Promise<AxiosResponse<GoogleResponse, Error>>,
  google: (token: string) => Promise<AxiosResponse<GoogleResponse, Error>>,
  authVerify: (token: string) => Promise<AxiosResponse<User, Error>>
  authLogout: () => Promise<AxiosResponse<User, Error>>,
}

const useAuth = (): AuthMethods => {
  const { mutateAsync: google } = useMutation(googleSingIn);
  const { mutateAsync: register } = useMutation(signUp);
  const { mutateAsync: login } = useMutation(signIn);
  const { mutateAsync: authVerify } = useMutation(verify);
  const { mutateAsync: authLogout } = useMutation(logout);

  return {
    register,
    google,
    login,
    authVerify,
    authLogout,
  };
};

export default useAuth;
