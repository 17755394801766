import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

import { getPopularProduct } from 'services/product.service';

export const QueryPopularProductsKey = 'getPopularProducts';

const usePopularProducts = (currency: string, options: Record<string, any>) => (
  useQuery(`${QueryPopularProductsKey}${currency}`, () => getPopularProduct(currency)
    .then((result: AxiosResponse<Product.Item[]>) => result.data), { ...options }));

export default usePopularProducts;
