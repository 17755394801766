import httpServices from 'services/http.service';
import { API_URLS } from 'constants/index';

export const getProductRegions = (category: string) => (
  (httpServices.get(API_URLS.PRODUCT.REGIONS, { params: { category } }))
);
export const getProducts = (category: string, region: string, currency: string) => (
  (httpServices.get(API_URLS.PRODUCT.LIST, { params: { category, region, currency } }))
);

export const getProduct = (category: string, region: string, originPrice: number) => (
  (httpServices.get(API_URLS.PRODUCT.GET, { params: { category, region, originPrice } }))
);

export const getPopularProduct = (currency:string) => (
  (httpServices.get(API_URLS.PRODUCT.POPULAR, { params: { currency }}))
);

export const getProductSearch = (term: string, currency: string) => (
  (httpServices.get(API_URLS.PRODUCT.SEARCH, { params: { term, currency } }))
);

export const getDiscountProducts = (currency: string) => (
  (httpServices.get(API_URLS.PRODUCT.DISCOUNT, { params: { currency } }))
);
