import { useCallback, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import useUserUpdate from '../api/useUserUpdate';
import { AppContext } from '../../contexts';

const useLanguageCheck = () => {
  const { locale, push, pathname, query, asPath } = useRouter();
  const { updateLanguage } = useUserUpdate();
  const { user, upsertUser } = useContext(AppContext);
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user?.lang && locale !== user?.lang) {
      i18n.changeLanguage(user?.lang);
      push({ pathname, query }, asPath, { locale: user?.lang });
    }
  }, [asPath, i18n, locale, pathname, push, query, user]);

  const changeLang = useCallback((locale) => {
    setLoading(true);
    if (user) {
      return updateLanguage(locale).then(() => {
        user.lang = locale;
        upsertUser(user);
        i18n.changeLanguage(locale);
        push({ pathname, query }, asPath, { locale });
        setLoading(false);
      }).catch(() => setLoading(false));
    }
    i18n.changeLanguage(locale);
    push({ pathname, query }, asPath, { locale });
    setLoading(false);
  }, [asPath, i18n, pathname, push, query, updateLanguage, upsertUser, user]);

  return {
    changeLang,
    changeLangLoading: loading,
  };
};

export default useLanguageCheck;
