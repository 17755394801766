import { ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

import styles from './index.module.scss';

export interface ModalProps {
  visible: boolean;
  children?: ReactNode;
  headerDisabled?: boolean;
  onClose: () => void;
}

const Modal = ({
  children, visible, onClose, headerDisabled,
}: ModalProps) => (
  <div>
    {
        visible
          ? (
            <div className={styles.wrapper}>
              {!headerDisabled && (
                <div className={styles.header}>
                  <FontAwesomeIcon className={styles.close} onClick={onClose} icon={faXmark} />
                </div>
              )}
              {children}
            </div>
          )
          : null
      }
  </div>
);

export default Modal;
