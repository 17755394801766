import { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';
import Loader from 'components/common/Loader';
import styles from './index.module.scss';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  loading?: boolean;
  type: 'submit' | 'button'
}

const OauthButton = ({ title, className, onClick, disabled, loading, type }: ButtonProps) => (
  <button
    disabled={disabled || false}
    className={classNames(styles.btn, className, { [styles.loading]: loading }, { [styles.disabled]: disabled })}
    onClick={onClick}
    type={type === 'submit' ? 'submit' : 'button'}
  >
    {title}
    {
      loading
        ? <Loader btn={loading} />
        : null
    }
  </button>
);

export default OauthButton;
