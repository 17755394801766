import {
  faXmark,
  faAngleUp,
  faLayerGroup,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import {
  useCallback, useState, useEffect,
} from 'react';
import Image from 'components/core/Image';
import { useTranslation } from 'next-i18next';
import styles from './index.module.scss';

const SHOW_TYPES = {
  CATEGORY: 'CATEGORY',
};

const MobNav = ({
  open, onClose, actions, categoryActions, handleLogout,
}: { open: boolean }) => {
  const [myActions, setMyActions] = useState<any>([]);
  const { t } = useTranslation('header');

  useEffect(() => {
    if (actions.length && categoryActions.length) {
      let data = [];
      data = data.concat(actions);
      const lastAction = data[data.length - 1];
      data[data.length - 1] = {
        icon: faLayerGroup, title: t('categories'), data: categoryActions, show: false, type: SHOW_TYPES.CATEGORY,
      };
      data.push(lastAction);
      setMyActions(data);
    } else if (actions.length) setMyActions(actions);
  }, [actions, categoryActions, handleLogout]);

  const handleShow = useCallback((type: string) => {
    const newActions = myActions.map((a) => {
      if (a?.type && a.type === type) a.show = !a.show;
      return a;
    });
    setMyActions(newActions);
  }, [myActions]);

  const actionsRender = useCallback((action: any, idx: number) => (
    <div key={idx.toString()}>
      {action?.href
        ? (
          <Link href={action.href}>
            <div className={styles.link}>
              {
                action.icon
                  ? (
                    <FontAwesomeIcon
                      className={styles.icon}
                      icon={action.icon}
                    />
                  )
                  : null
              }
              <span className={styles.title}>{action.title}</span>
            </div>
          </Link>
        ) : null }

      { action?.onClick ? (
        <div
          role='presentation'
          className={styles.link}
          onClick={action?.onClick}
        >
          {action.icon
            ? (
              <FontAwesomeIcon
                className={styles.icon}
                icon={action.icon}
              />
            )
            : null}
          <span className={styles.title}>{action.title}</span>
        </div>
      ) : null }

      { action?.type ? (
        <>
          <div
            role='presentation'
            className={styles.link}
            onClick={() => handleShow(SHOW_TYPES.CATEGORY)}
          >
            {
              action.icon
                ? (
                  <FontAwesomeIcon
                    className={styles.icon}
                    icon={action.icon}
                  />
                )
                : null
            }
            <span className={styles.title}>{action.title}</span>
            <FontAwesomeIcon
              className={styles.arrow}
              icon={faAngleUp}
              transform={{ rotate: action.show ? 0.1 : 180 }}
            />
          </div>
          {
            action?.show
              ? action?.data?.map((item, secondIdx) => (
                <div className={styles.subLink} key={secondIdx.toString()}>
                  <Link href={item.href} onClick={onClose}>
                    <div className={styles.wrap}>
                      {item.image
                        ? (
                          <Image
                            className={styles.img}
                            src={item?.image}
                            alt='Card'
                            width={30}
                            height={30}
                          />
                        )
                        : null}
                      {item.title}
                    </div>
                  </Link>
                </div>
              ))
              : null
          }
        </>
      ) : null }
    </div>
  ), [handleShow, onClose]);

  if (!open) return null;
  return (
    <div className={styles.nav}>
      <div role='presentation' className={styles.mask} onClick={onClose} />
      <div className={styles.wrapper}>
        <div className={styles.mobHeader}>
          <Link href='/'>
            <Image
              src='/logo.png'
              alt='Logo'
              width={40}
              height={40}
              className={styles.logo}
            />
          </Link>
          <FontAwesomeIcon
            onClick={onClose}
            className={styles.close}
            icon={faXmark}
          />
        </div>
        <div>
          { myActions?.map(actionsRender) }
        </div>
      </div>
    </div>
  );
};

export default MobNav;
