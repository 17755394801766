import { useCallback, useContext, useMemo } from 'react';
import { useRouter } from 'next/router';
import { AppContext } from 'contexts/';
import { CURRENCY } from 'constants/';
import { Quantico } from 'next/font/google';
import classNames from 'classnames';
import Image from '../../../Image';
import styles from './index.module.scss';
import { SEARCH_ITEM_TYPES } from '../../../../../constants/search';

const quantico = Quantico({
  weight: '400',
  subsets: ['latin'],
});
const Item = (data) => {
  const {
    assets, originPrice, name, discount, price, discountPrice, href, handleClose, type, image,
  } = data;
  const { push } = useRouter();
  const handleClick = useCallback(() => {
    push(href).then();
    handleClose();
  }, [handleClose, push, href]);
  const { currency } = useContext(AppContext);

  const originName = useMemo(() => {
    if (type === SEARCH_ITEM_TYPES.CARD) {
      return (
        <>
          <span className={styles.originPrice}>
            $
            {originPrice / 100}
          </span>
          <span className={styles.name}>{name}</span>
        </>
      );
    }
    if (type === SEARCH_ITEM_TYPES.CATEGORIES) return <span className={styles.name}>{name}</span>;
    return null;
  }, [type, originPrice, name]);

  return (
    <div role='presentation' className={styles.item} onClick={handleClick}>
      <div className={styles.wrapper}>
        <Image
          className={styles.logo}
          loader={() => image || assets?.image}
          src={image || assets?.image}
          alt='Card'
          width={40}
          height={25}
        />
        {originName}
      </div>
      <div>
        {
          discount
            ? (
              <span className={classNames(styles.discountPrice, quantico.className)}>
                {CURRENCY[currency].sign}
                {' '}
                {(price.value).toFixed(2)}
              </span>
            )
            : null
        }
        {
          type === SEARCH_ITEM_TYPES.CARD
            ? (
              <span className={classNames(styles.price, quantico.className)}>
                {CURRENCY[currency].sign}
                {' '}
                {(discountPrice || price.value).toFixed(2)}
              </span>
            )
            : null
          }
      </div>
    </div>
  );
};

export default Item;
