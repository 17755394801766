import Link from 'next/link';
import {
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'components/core/Image';
import styles from './index.module.scss';

interface HeaderModal {
  open: boolean,
  actions: [{ href?: string | undefined, icon?: IconDefinition, title: string, onClick?: () => void | undefined }],
  user?: User
}

const Modal = ({ open, actions, user }: HeaderModal) => (
  <div>
    {
      open
        ? (
          <div className={styles.modal}>
            { user?.username ? <div className={styles.username}>{user.username}</div> : null }
            {
              actions.map((action, idx: number) => (
                <div key={idx.toString()}>
                  {action?.href
                    ? (
                      <Link href={action.href}>
                        <div className={styles.link}>
                          { action.icon ? (
                            <FontAwesomeIcon
                              className={styles.icon}
                              icon={action.icon}
                            />
                          ) : null }
                          {action?.image
                            ? (
                              <Image
                                className={styles.img}
                                src={action?.image}
                                alt='Card'
                                width={30}
                                height={30}
                              />
                            ) : null}
                          <span className={styles.title}>{action.title}</span>
                        </div>
                      </Link>
                    ) : null}

                  {action?.onClick
                    ? (
                      <div
                        role='presentation'
                        className={styles.link}
                        onClick={action?.onClick}
                      >
                        { action.icon ? (
                          <FontAwesomeIcon
                            className={styles.icon}
                            icon={action.icon}
                          />
                        ) : null }
                        <span className={styles.title}>{action.title}</span>
                      </div>
                    ) : null}
                </div>
              ))
            }
          </div>
        )
        : null
      }
  </div>
);

export default Modal;
