import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { getCategories } from 'services/category.service';

export const QueryKey = 'getCategories';

const useCategories = () => useQuery(QueryKey, () => getCategories()
  .then((result: AxiosResponse<Category[]>) => result.data));

export default useCategories;
