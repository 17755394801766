import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTelegram } from '@fortawesome/free-brands-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Image from 'next/image';
import Link from 'next/link';
import { APP_URLS } from 'constants/index';
import classNames from 'classnames';
import { VisaIcon, MastercardIcon } from 'components/icons';
import { useTranslation } from 'next-i18next';

import styles from './index.module.scss';

const Footer = () => {
  const { t } = useTranslation('footer');

  return (
    <footer className={styles.footer}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.storeInfo}>
            <Link href='/'>
              <div className={styles.filler}>
                <Image src='/logo.png' className={styles.logo} width={50} height={50} alt='Logo' />
              </div>
            </Link>
            <div className={styles.info}>
              <div className={styles.support} id='contacts'>
                <div className={styles.supportTitle}>{t('support')}</div>
                <div className={styles.email}>support@hotcaddelivery.com</div>
              </div>
              {t('description')}
            </div>
          </div>
          <div className={styles.links}>
            <div className={styles.title}>{t('terms.title')}</div>
            <Link className={classNames(styles.item, styles.link)} href={APP_URLS.ABOUT_US}>{t('terms.links.about')}</Link>
            <Link className={classNames(styles.item, styles.link)} href={APP_URLS.TERMS}>{t('terms.links.terms')}</Link>
            <Link className={classNames(styles.item, styles.link)} href={APP_URLS.PRIVACY}>{t('terms.links.policy')}</Link>
            <Link className={classNames(styles.item, styles.link)} href={APP_URLS.SHIPPING_AND_PAYMENT}>{t('terms.links.shipping')}</Link>
          </div>
          <div className={styles.links}>
            <div className={styles.title}>{t('followUs')}</div>
            <Link href='https://instagram.com/hotcarddelivery'>
              <FontAwesomeIcon className={classNames(styles.item, styles.instagram)} icon={faInstagram as IconProp} />
            </Link>
            <Link href='https://t.me/hotcarddelivery'>
              <FontAwesomeIcon className={classNames(styles.item, styles.telegram)} icon={faTelegram as IconProp} />
            </Link>
            <div id='paymentMethods' className={classNames(styles.title, styles.paymentMethods)}>
              {t('paymentMethods')}
            </div>
            <VisaIcon />
            <MastercardIcon />
          </div>
        </div>
      </div>
      <div className={styles.company}>@HotCard 2023-2024</div>
    </footer>
  );
};

export default Footer;
