import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faXmark } from '@fortawesome/free-solid-svg-icons';
import { ChangeEvent, useCallback, useContext, useRef, useState } from 'react';
import useOutside from 'hooks/common/useOutside';
import { OutsideHookTypes } from 'constants/index';
import debounce from 'utils/helpers/debounce';
import useProductSearch from 'hooks/api/useProductSearch';
import Loader from 'components/common/Loader';
import { useTranslation } from 'next-i18next';

import styles from './index.module.scss';
import Item from './components/Item';
import { SEARCH_ITEM_TYPES } from '../../../constants/search';
import {AppContext} from "../../../contexts";

const Search = ({ search, setSearch }: { search: boolean, setSearch: (params: boolean) => void }) => {
  const searchRef = useRef(null);
  const inpRef = useRef<HTMLInputElement>(null);
  const [term, setTerm] = useState('');
  const [value, setValue] = useState('');
  const debounceFunction = useRef<(e: string) => void>(debounce((v: string) => {
    setTerm(v);
  }, 750));
  useOutside({
    ref: searchRef,
    wiretapState: search,
    type: OutsideHookTypes.SEARCH,
    func: () => {
      setSearch(false);
      setTerm('');
      setValue('');
    },
  });
  const { currency } = useContext(AppContext);
  const { data: { products, categories }, isLoading } = useProductSearch(term, currency, { enabled: !!term, retry: 0 });
  const onChange = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    if (debounceFunction.current) debounceFunction.current(target.value);
    setValue(target.value);
  }, [debounceFunction, setValue]);
  const { t } = useTranslation('header');

  const handleClose = useCallback(() => {
    setSearch(false);
    setTerm('');
    setValue('');
  }, [setSearch, setTerm]);

  const productsRender = useCallback((item: Product.Item, idx: number) => (
    <Item
      {...item}
      href={`/${item.category}/${item.region}/${item.originPrice}`}
      handleClose={handleClose}
      type={SEARCH_ITEM_TYPES.CARD}
      key={idx.toString()}
    />
  ), [handleClose]);

  const categoriesRender = useCallback((item: Category, idx: number) => (
    <Item
      {...item}
      href={`/${item.name}`}
      handleClose={handleClose}
      type={SEARCH_ITEM_TYPES.CATEGORIES}
      key={idx.toString()}
    />
  ), [handleClose]);

  const handleSearch = useCallback(() => {
    setSearch(true);
    if (inpRef.current) inpRef.current.focus();
  }, [setSearch]);

  return (
    <div className={classNames(styles.searchContainer, { [styles.searchOpen]: search })} ref={searchRef}>
      <FontAwesomeIcon
        icon={faMagnifyingGlass}
        onClick={handleSearch}
        className={styles.searchBtn}
      />
      <input
        ref={inpRef}
        type='text'
        name=''
        id=''
        value={value}
        placeholder={t('search.find')}
        onChange={onChange}
        className={classNames({ [styles.inpHide]: !search, [styles.inpShow]: search })}
      />
      <FontAwesomeIcon
        icon={faXmark}
        onClick={handleClose}
        className={classNames({ [styles.xmarkHide]: !search, [styles.xmarkShow]: search })}
      />
      {search && term && !isLoading
        ? (
          <div className={styles.cardContainer}>
            <h4>{t('search.card')}</h4>
            {
              products.length
                ? products?.map(productsRender)
                : <span className={styles.empty}>{t('search.noCardsMsg')}</span>
            }
            <h4>{t('search.categories')}</h4>
            {
              categories.length
                ? categories?.map(categoriesRender)
                : <span className={styles.empty}>{t('search.noCategoriesMsg')}</span>
            }
          </div>
        )
        : null}
      {
        search && isLoading ? (
          <div className={classNames(styles.cardContainer, styles.cardContainerLoader)}>
            <Loader />
          </div>
        ) : null
      }
    </div>
  );
};

export default Search;
