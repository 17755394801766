import { useCallback, useEffect, useState } from 'react';

const useScroll = ({ type }: { type: string }) => {
  const [scroll, setScroll] = useState<number>(0);

  const handleScroll = useCallback(() => {
    if (type && scroll && !window.scrollY) setScroll(window.scrollY);
    if (type && !scroll && window.scrollY) setScroll(window.scrollY);
  }, [scroll, type]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return {
    scroll,
  };
};

export default useScroll;
