import { getProductSearch } from 'services/product.service';
import { useQuery, UseQueryOptions } from 'react-query';
import { AxiosResponse } from 'axios';

export const QueryKey = 'getProductSearch';

export interface SearchResult {
  products: Product.Item[];
  categories: Category[];
}

export function useProductSearch(term: string, currency: string, options?: UseQueryOptions<SearchResult, Error>) {
  const queryKey = `${QueryKey}${term}${currency}`;

  const query = useQuery<SearchResult, Error>(
    queryKey,
    () => getProductSearch(term, currency).then((result: AxiosResponse<SearchResult>) => result.data),
    {
      ...options,
    },
  );

  return {
    ...query,
    data: {
      products: query.data?.products || [],
      categories: query.data?.categories || [],
    },
  };
}

export default useProductSearch;
