import Modal from 'components/core/Modal';
import Button from 'components/core/Button';
import { useCallback, useContext } from 'react';
import useUserUpdate from 'hooks/api/useUserUpdate';
import { useRouter } from 'next/router';

import { NoticeContext } from 'contexts/notification';
import { AppContext } from 'contexts/index';
import { CartContext } from 'contexts/cartContext';
import { useTranslation } from 'next-i18next';
import styles from './index.module.scss';

const AccountDeleteModal = ({ visible, handleClose }: { visible: boolean, handleClose: () => void }) => {
  const { removeAccount } = useUserUpdate();
  const { message } = useContext(NoticeContext);
  const { upsertUser } = useContext(AppContext);
  const { removeItems } = useContext(CartContext);
  const { push } = useRouter();
  const { t } = useTranslation('header');

  const handleDeleteAccont = useCallback(async () => {
    await removeAccount().then(() => {
      upsertUser(undefined);
      removeItems();
      message('You have successfully deleted your account', 'success');
      handleClose();
      void push('/');
    }).catch((e) => {
      message('Something went wrong, try later', 'error');
    });
  }, [handleClose, message, push, removeAccount, removeItems, upsertUser]);

  return (
    <Modal visible={visible} headerDisabled>
      <div className={styles.wrapper}>
        <div className={styles.title}>
          {t('deleteAccountConfirmMessage')}
        </div>
        <div>
          <Button title={t('deleteAccountConfirmAccept')} className={styles.btn} onClick={handleDeleteAccont} />
          <Button title={t('deleteAccountConfirmCancel')} className={styles.btn} onClick={handleClose} />
        </div>
      </div>
    </Modal>
  );
};

export default AccountDeleteModal;
