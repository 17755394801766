import NextImage, { ImageProps } from 'next/image';
import { useState } from 'react';

const Image = ({ src: incomeSrc, ...props }: ImageProps) => {
  const [src, setSrc] = useState(incomeSrc);

  return (
    <NextImage {...props} src={src} onError={() => setSrc('notFound.png')} />
  );
};

export default Image;
