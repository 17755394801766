import { useMutation } from 'react-query';
import { useRouter } from 'next/router';
import { useCallback, useContext } from 'react';
import { getStorage } from 'services/localStorage.service';
import { upsertCart, deleteAccount, userCurrency, userLanguage, cartCurrency } from 'services/user.service';
import { CART } from 'constants/cart';
import { AppContext } from 'contexts';

const useUserUpdate = () => {
  const { mutateAsync: updateCart } = useMutation(upsertCart);
  const { mutateAsync: deleteUser } = useMutation(deleteAccount);
  const { mutateAsync: updateCurrency } = useMutation(userCurrency);
  const { mutateAsync: updateLanguage } = useMutation(userLanguage);
  const { mutateAsync: invalidateCart } = useMutation(cartCurrency);
  const { upsertUser } = useContext(AppContext);
  const { push, query } = useRouter();

  const addUser = useCallback((user: User) => {
    upsertUser(user);
    const cart = getStorage(CART) as Product.CartItem[];
    if (user && cart && !user.cart.length) {
      updateCart(cart.map((u: Product.CartItem) => ({
        originPrice: u.originPrice,
        amount: u.amount,
        product: u.id,
      }))).catch(() => null);
    }
    if (user && query.page) push('/checkout').then(() => null).catch(() => null);
    else if (user) push('/').then(() => null).catch(() => null);
  }, [push, updateCart, upsertUser, query]);

  const removeAccount = useCallback(() => deleteUser(), [deleteUser]);

  return {
    addUser,
    removeAccount,
    userCurrency: updateCurrency,
    updateLanguage,
    invalidateCart,
  };
};

export default useUserUpdate;
