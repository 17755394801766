import classNames from 'classnames';
import Link from 'next/link';

import useCategories from 'hooks/api/useCategories';
import useScroll from 'hooks/common/useScroll';
import Search from 'components/core/Search';
import Image from 'components/core/Image';
import useAuth from 'hooks/api/useAuth';
import useUserUpdate from 'hooks/api/useUserUpdate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleUser, faCartShopping, faBars, faUnlockKeyhole, faArrowRightFromBracket, faFolderTree, faTrash, faXmark,
} from '@fortawesome/free-solid-svg-icons';
import {
  useContext, useState, useCallback, useMemo, useRef,
} from 'react';
import { CartContext } from 'contexts/cartContext';
import { AppContext } from 'contexts';
import { NoticeContext } from 'contexts/notification';
import { APP_URLS, CURRENCY, CURRENCY_ROUTES, ECurrency, LOCALE } from 'constants/index';

import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import he from 'he';
import Modal from 'components/core/Modal';
import useLanguageCheck from 'hooks/common/useLanguageCheck';
import useOutside from 'hooks/common/useOutside';

import { Quantico } from 'next/font/google';
import styles from './index.module.scss';
import DropdownMenu from './components/DropdownMenu';
import MobNav from './components/MobNav';
import AccountDeleteModal from './components/AccountDeleteModal';
import Loader from '../../../components/common/Loader';

const quantico = Quantico({
  weight: '400',
  subsets: ['latin'],
});
const Header = () => {
  const { scroll } = useScroll({ type: 'header' });
  const [search, setSearch] = useState<boolean>(false);
  const { totalAmount = 0, totalPrice = 0, updateCartItems } = useContext(CartContext);
  const { user, upsertUser, updateCurrency, currency } = useContext(AppContext);
  const { data: categories = [] } = useCategories();
  const { authLogout } = useAuth();
  const { userCurrency } = useUserUpdate();
  const { message } = useContext(NoticeContext);
  const [burger, setBurger] = useState<boolean>(false);
  const [AccountDeleteModalVisible, setAccountDeleteModalVisible] = useState(false);
  const { t } = useTranslation('header');
  const router = useRouter();
  const { locale, route } = router;
  const [currencyModal, setCurrencyModal] = useState(false);
  const currencyModalRef = useRef(null);
  const { invalidateCart } = useUserUpdate();

  const { cart } = useContext(CartContext);
  useOutside({
    ref: currencyModalRef,
    wiretapState: currencyModal,
    func: () => {
      setCurrencyModal(false);
    },
  });

  const { changeLang, changeLangLoading } = useLanguageCheck();

  const handleLogout = useCallback(async () => {
    await authLogout().then(() => {
      upsertUser();
    }).catch((e) => message(e?.message, 'error'));
  }, [authLogout, message, upsertUser]);

  const actions = useMemo(() => {
    const data = [];
    if (user) {
      data.push(
        { href: '/recovery', icon: faUnlockKeyhole, title: t('recoveryPassword') },
        { href: '/orders', icon: faFolderTree, title: t('orders') },
        { icon: faTrash, title: t('deleteAccount'), onClick: () => setAccountDeleteModalVisible(true) },
        { icon: faArrowRightFromBracket, title: t('signOut'), onClick: handleLogout },
      );
    } else data.push({ icon: faArrowRightFromBracket, title: t('login'), href: '/login' });
    return data;
  }, [user, handleLogout, t]);

  const categoryActions = useMemo(
    () => categories.map((i) => ({ href: `/${i.name}`, title: i.name, image: i.image })),
    [categories],
  );

  const handleChangeCurrency = useCallback((value: ECurrency) => {
    if (user) {
      userCurrency(value).then(() => {
        user.currency = value;
        if (CURRENCY_ROUTES.includes(route)) {
          router.replace({
            query: { ...router.query, currency: value },
          });
        }
        upsertUser(user);
      }).catch((e) => message(e?.message, 'error'));
    }

    invalidateCart({
      cart: cart.map((c) => ({ ...c, product: c.id })),
      currency: value,
    }).then((data) => {
      updateCartItems(data.data);
    }).catch(null);
    updateCurrency(value);
  }, [user, invalidateCart, cart, updateCurrency, userCurrency, route, upsertUser, router, message, updateCartItems]);

  return (
    <header className={classNames(styles.header, { [styles.headerColor]: scroll, [styles.headerNoColor]: !scroll }, quantico.className)}>
      <div className={styles.container}>
        <div className={styles.leftContainer}>
          <Link href='/'>
            <div className={styles.filler}>
              <Image
                src='/logo.png'
                alt='Logo'
                width={50}
                height={50}
                className={styles.logo}
              />
            </div>
          </Link>
          {
            !search
              ? (
                <div
                  role='presentation'
                  className={styles.currencyInfo}
                  onClick={() => setCurrencyModal((s) => !s)}
                >
                  {he.decode(LOCALE[locale].flag)}
                  {' '}
                  <span>|</span>
                  {' '}
                  {CURRENCY[currency].val}
                  {' '}
                  <span>-</span>
                  {CURRENCY[currency].sign}
                </div>
              )
              : null
          }
        </div>

        <div className={styles.rightContainer}>
          {
            !search
              ? (
                <div className={styles.category}>
                  {t('categories')}
                  <div className={styles.categoryWrap}>
                    <DropdownMenu open actions={categoryActions} />
                  </div>
                </div>
              )
              : null
          }
          <Search search={search} setSearch={setSearch} />
          {
            user
              ? (
                <div className={styles.user}>
                  <FontAwesomeIcon
                    className={styles.userSvg}
                    icon={faCircleUser}
                  />
                  <div className={styles.modal}>
                    <DropdownMenu open actions={actions} user={user} />
                  </div>
                </div>
              )
              : (
                <Link href={APP_URLS.LOGIN}>
                  <div className={styles.user}>
                    <FontAwesomeIcon
                      className={styles.userSvg}
                      icon={faCircleUser}
                    />
                    <span className={styles.login}>{t('login')}</span>
                  </div>
                </Link>
              )
          }
          <Link href={APP_URLS.CHECKOUT}>
            <div className={styles.cart}>
              <FontAwesomeIcon
                className={styles.cartSvg}
                icon={faCartShopping}
              />
              <span className={styles.cartAmount}>{totalAmount}</span>
              {
                totalPrice !== 0
                  ? (
                    <span className={styles.cartTotal}>
                      $
                      {(totalPrice).toFixed(2)}
                    </span>
                  )
                  : null
              }
            </div>
          </Link>
          <div className={styles.burgerMenu}>
            <FontAwesomeIcon
              className={styles.burgerSvg}
              onClick={() => setBurger((s) => !s)}
              icon={faBars}
            />
          </div>
        </div>
      </div>
      <MobNav
        handleLogout={handleLogout}
        categoryActions={categoryActions}
        actions={actions}
        open={burger}
        onClose={() => setBurger((s) => !s)}
      />
      <AccountDeleteModal visible={AccountDeleteModalVisible} handleClose={() => setAccountDeleteModalVisible(false)} />
      <Modal visible={currencyModal} headerDisabled onClose={() => null}>
        <div className={styles.currencyModal}>
          <div className={styles.modalContent} ref={currencyModalRef}>
            <div className={styles.close}>
              <span className={styles.btn}>
                <FontAwesomeIcon
                  className={styles.svg}
                  onClick={() => setCurrencyModal((s) => !s)}
                  icon={faXmark}
                />
              </span>
            </div>
            <h4>{ t('currency.title')}</h4>
            <div className={styles.item}>
              <span className={styles.itemName}>{ t('currency.currencyTitle')}</span>
              <select
                name='CURRENCY'
                value={currency}
                className={styles.select}
                onChange={(e) => handleChangeCurrency(e.target.value as ECurrency)}
              >
                {
                  Object.entries(CURRENCY).map(([key, value], idx) => (
                    <option key={idx.toString()} value={value.val}>{value.title}</option>
                  ))
                }
              </select>
            </div>
            <div className={styles.item}>
              <span className={styles.itemName}>
                { t('currency.languageTitle')}
              </span>
              {changeLangLoading
                ? (
                  <div className={styles.loadingLoaderWrap}>
                    <Loader />
                  </div>
                )
                : (
                  <select
                    className={styles.select}
                    name='LANGUAGE'
                    value={locale}
                    disabled={changeLangLoading}
                    onChange={(e) => changeLang(e.target.value)}
                  >
                    {
                    Object.entries(LOCALE).map(([key, value], idx) => (
                      <option key={idx.toString()} value={key}>{value.title}</option>
                    ))
                  }
                  </select>
                )}
            </div>
          </div>
        </div>
      </Modal>
    </header>
  );
};

export default Header;
